<script setup lang="ts">
import { computed } from 'vue'

import RoomieAccordion from '@lahaus-roomie/roomie/src/components/RoomieAccordion/index.vue'

import './_index.scss'

const { t, locale } = useI18n()

const faqs = computed(() => {
  const numFaqs = locale.value === 'mx' ? 5 : 4

  return Array.from({ length: numFaqs }, (_, i) => {
    const keyName = `faqs.faq${i + 1}`
    return {
      title: t(`${keyName}.title`),
      content: t(`${keyName}.content`),
      lhId: `home-faqs-toggle-accordion-${i + 1}`,
      initiallyOpen: i === 0
    }
  })
})

</script>

<script lang="ts">
export default {
  name: 'HomeFaqs'
}
</script>

<template>
  <section class="home-faqs px-lh-24 mb-144 bg-brand-50">
    <div class="home-faqs__content max-w-[97rem] mx-auto lg:grid lg:gap-2 pt-96">
      <div class="col-auto pr-16">
        <p class="text-primary-600 mb-8">
          {{ t('subtitle').toUpperCase() }}
        </p>

        <h3 class="text-32 font-semibold text-carbon-800 mb-24">
          {{ t('title') }}
        </h3>
      </div>

      <div class="col-auto">
        <RoomieAccordion
          v-for="(faq, index) in faqs"
          :id="faq.lhId"
          :key="index"
          class="home-faqs-toggle-accordion"
          :is-initially-open="faq.initiallyOpen"
          :title="faq.title"
          :tracking-lh-id="faq.lhId">
          <template #content>
            <p
              v-sanitize="faq.content"
              class="text-carbon-600 text-16" />
          </template>
        </RoomieAccordion>
      </div>
    </div>
  </section>
</template>

<i18n src="./i18n.json" lang="json" />
