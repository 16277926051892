<script lang="ts" setup>
import { onMounted, ref } from 'vue'

import ProjectsByLocation from '@/components/ProjectsByLocation/index.vue'
import InvestmentFixedCta from '@/components/InvestmentFixedCta/index.vue'
import MediaReviews from '@/components/MediaReviews/index.vue'
import HomeBenefits from '@/components/HomeBenefits/index.vue'
import HomeTrajectory from '@/components/HomeTrajectory/index.vue'
import HomeFaqs from '@/components/home/HomeFaqs/index.vue'
import AppHero from '@/components/AppHero/index.vue'
import HomeTestimonials from '@/components/HomeTestimonials/index.vue'

useSeoMeta({
  robots: 'index, follow',
  title: 'La Haus, desarrollos inmobiliarios verificados para invertir',
  ogTitle: 'La Haus, desarrollos inmobiliarios verificados para invertir',
  description: 'Compra los mejores desarrollos inmobiliarios, departamentos y casas en méxico. ¡invierte en bienes raíces y benefíciate de la renta hotelera y turística!',
  ogDescription: 'Compra los mejores desarrollos inmobiliarios, departamentos y casas en méxico. ¡invierte en bienes raíces y benefíciate de la renta hotelera y turística!',
  ogImage: 'https://lahaus.imgix.net/static/default/default-share-social-media-v2.jpg',
  ogLocale: 'es-MX',
  ogSiteName: 'La Haus',
  twitterCard: 'summary',
  twitterSite: '@lahaus_mx',
  twitterCreator: '@lahaus_mx'
})

const displayFixed = ref(false)

const setObserverToAnimateSections = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('page-home__animated-section--active')
        observer.unobserve(entry.target)
      }
    })
  }, { threshold: 0.12 })

  const sections = document.querySelectorAll('.page-home__animated-section')
  sections.forEach((section) => {
    observer.observe(section)
  })
}

const setFixedCtaObserver = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      displayFixed.value = entry.isIntersecting
    })
  }, { threshold: 0 })

  const control = document.querySelector('.fixed-cta-section-control') as HTMLDivElement
  observer.observe(control)
}

const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId)
  section?.scrollIntoView({ behavior: 'smooth' })
}

onMounted(() => {
  setObserverToAnimateSections()
  setFixedCtaObserver()
})
</script>

<script lang="ts">
export default {
  name: 'PageHome'
}
</script>

<template>
  <div class="page-home relative">
    <div class="page-home__content relative">
      <AppHero />

      <HomeMediaBanner
        class="page-home__animated-section"
        @go-to-section="scrollToSection" />

      <ProjectsByLocation />

      <div class="fixed-cta-section-control">
        <HomeBenefits
          class="page-home__animated-section"
          @go-to-section="scrollToSection" />

        <HomeTestimonials />

        <HomeTrajectory />

        <MediaReviews id="media-reviews-section" />

        <HomeFaqs />

        <Transition name="slide-fade">
          <InvestmentFixedCta v-if="displayFixed" />
        </Transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(90px);
}

.page-home {
  &__animated-section {
    opacity: 0;

    &--active {
      animation: fade-in-up-home 0.6s ease-out forwards;
    }
  }
}

@keyframes fade-in-up-home {
  from {
    opacity: 0;
    transform: translateY(90px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
