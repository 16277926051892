<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue'
import { delay } from '@/utils/delay'

const { t } = useI18n()

const trajectoryList = computed(() => ['project', 'investment', 'transaction'])

const amountsValues = reactive([0, 0, 0])
const setRealvalues = async () => {
  for (let index = 0; index < amountsValues.length; index++) {
    const trayectoryKey = trajectoryList.value[index]
    const limit = parseInt(t(`${trayectoryKey}.value`))

    const jumps = limit > 1000 ? 250 : 5
    while (amountsValues[index] < limit) {
      await delay(140)
      amountsValues[index] += jumps
    }
  }
}
const setNumbersObservers = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setRealvalues()
        observer.disconnect()
      }
    })
  }, { threshold: 0.5 })

  const amountsUl = document.querySelector('.home-trajectory__amounts')
  observer.observe(amountsUl!)
}

onMounted(() => {
  setNumbersObservers()
})
</script>

<script lang="ts">
export default {
  name: 'HomeTrajectory'
}
</script>

<template>
  <section class="home-trajectory bg-brand-50 py-56 overflow-hidden">
    <div class="w-full max-w-[97rem] mx-auto px-24 xl:px-0">
      <div>
        <span class="text-14 text-carbon-600 tracking-[.05rem]">
          {{ t('sectionName') }}
        </span>

        <h3 class="max-w-5xl text-24 md:text-32 font-semibold leading-28 md:leading-[3.5rem] mb-20 mt-8 md:mb-40">
          <span>
            {{ t('title.part1') }}
          </span>

          <span class="text-accent-700">
            {{ t('title.part2') }}
          </span>

          <span class="font-normal">
            {{ t('title.part3') }}
          </span>
        </h3>

        <ul class="home-trajectory__amounts grid gap-24 md:flex md:items-start md:justify-between">
          <li
            v-for="(trajectory, index) in trajectoryList"
            :key="trajectory"
            class="md:pl-24 md:border-l border-carbon-light-300">
            <div
              class="h-max text-40 lg:text-80 font-medium text-primary-600 flex leading-40 lg:leading-72"
              :class="{ 'grid': trajectory === 'transaction' }">
              <p class="flex items-end">
                <span v-if="trajectory === 'transaction'">
                  $
                </span>

                {{ amountsValues[index] }}

                <span
                  v-if="trajectory === 'transaction'"
                  class="text-18 text-carbon-600 pb-2">
                  {{ t(`${trajectory}.currency`) }}
                </span>
              </p>

              <span
                :class="{ 'text-32': trajectory === 'transaction' }"
                class="text-primary-600">
                {{ t(`${trajectory}.type`) }}
              </span>
            </div>

            <p class="text-18 text-carbon-600">
              {{ t(`${trajectory}.description`) }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.home-trajectory {
  & li:nth-child(1) {
    @apply md:p-0 md:border-none;
  }
}
</style>

<i18n src="./i18n.json" lang="json" />
