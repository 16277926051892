<script lang="ts" setup>

const emit = defineEmits(['goToSection'])

const mediaIcons = [
  {
    url: 'https://lahaus.imgix.net/static/lh-landing-v2/reviews/crunchbase.png?auto=compress,format,enhance',
    alt: 'crunchbase logo',
    styles: 'w-120 md:w-160 h-18 md:h-24'
  },
  {
    url: 'https://lahaus.imgix.net/static/lh-landing-v2/reviews/forbes_logo.svg?auto=compress,format,enhance',
    alt: 'forbes logo',
    styles: 'w-62 md:w-84 h-18 md:h-20'
  },
  {
    url: 'https://lahaus.imgix.net/static/lh-landing-v2/reviews/bloomberg.png?auto=compress,format,enhance',
    alt: 'bloomberg logo',
    styles: 'w-90 md:w-130 h-16 md:h-24 -mb-4'
  }
]
</script>

<script lang="ts">
export default {
  name: 'HomeMediaBanner'
}
</script>

<template>
  <section class="home-media-banner bg-primary-50 px-24 py-32 md:py-40 xl:px-0">
    <div class="w-full max-w-[97rem] mx-auto grid lg:flex gap-20 lg:gap-30">
      <div class="text-20 md:text-24 grid">
        <span>
          Jeff Bezos y Maluma,
        </span>

        <span class="font-semibold flex gap-8 whitespace-nowrap">
          invirtieron en La Haus.
          <button
            id="home-media-banner-go-to-section-button"
            data-lh-id="home-media-banner-go-to-section-button"
            class="pt-4 text-16 md:text-18 text-brand-800 fill-brand-800 flex items-center gap-8 whitespace-nowrap"
            @click="emit('goToSection', 'media-reviews-section')">
            Ver más
            <div class="w-24 h-24 pointer-events-none">
              <NuxtIsland name="IconChevronRight" />
            </div>
          </button>
        </span>
      </div>

      <div class="w-full flex items-center justify-between">
        <img
          v-for="icon in mediaIcons"
          :key="icon.alt"
          :src="icon.url"
          :alt="icon.alt"
          :class="[icon.styles]">
      </div>
    </div>
  </section>
</template>
