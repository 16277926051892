<script lang="ts" setup>
import { computed } from 'vue'
import RoomieButtonLink from '@lahaus-roomie/roomie/src/components/RoomieButtonLink/index.vue'
import './_index.scss'

const { t } = useI18n()

const simulatorLink = computed(() => '/venta/propiedades')
</script>

<script lang="ts">
export default {
  name: 'InvestmentFixedCta'
}
</script>

<template>
  <div class="z-1 fixed bottom-0 left-0 w-full bg-white py-lh-24 px-lh-8 md:py-lh-14 md:px-lh-40 lg:px-lh-56 flex justify-center items-center md:justify-between shadow-elevation-light-level-8">
    <p class="text-16 hidden md:block">
      {{ t('description') }}
    </p>

    <RoomieButtonLink
      id=""
      class="w-full md:max-w-md"
      variant="filled"
      data-lh-id="home-button-fixed-simulate"
      size="md"
      :url="simulatorLink"
      target-type="self"
      :aria-label="t('cta')">
      <span class="">{{ t('cta') }}</span>
    </RoomieButtonLink>
  </div>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
