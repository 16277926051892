<script lang="ts" setup>
import { ref, onMounted, onUnmounted, onBeforeUnmount } from 'vue'

import './_index.scss'

const { rt, tm, t } = useI18n()
const reviews: any = tm('reviews')

const currentDot = ref(0)
const showLeftButton = ref(false)
const showRightButton = ref(true)

function updateDots (entries: IntersectionObserverEntry[]) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const target = entry.target as HTMLDivElement
      currentDot.value = parseInt(target.dataset.review ?? '0')
    }
  })
}

const sliderRef = ref<any>(null)
const intervalId = ref<number | null>(null)

const checkScroll = () => {
  const { scrollLeft, scrollWidth, offsetWidth } = sliderRef.value
  showLeftButton.value = scrollLeft > 0
  showRightButton.value = scrollLeft < scrollWidth - offsetWidth
}

function handleNextClick (manual = false) {
  if (manual) clearInterval(intervalId.value!)

  sliderRef.value.scrollLeft += sliderRef.value.offsetWidth + 1
  checkScroll()
}

function handlePreviousClick (manual = false) {
  if (manual) clearInterval(intervalId.value!)

  sliderRef.value.scrollLeft -= sliderRef.value.offsetWidth + 1
  checkScroll()
}

function loadObservers () {
  const observer = new IntersectionObserver(updateDots, {
    rootMargin: '0px',
    threshold: 0.5
  })

  const targets = document.querySelectorAll('[data-review]')

  targets.forEach((target) => {
    observer.observe(target)
  })
}

const goToLink = (url: string) => {
  navigateTo(url, {
    external: true,
    open: {
      target: '_blank'
    }
  })
}

const startAutoSlide = () => {
  intervalId.value = window.setTimeout(() => {
    handleNextClick()
  }, 2000)
}
const setAutoSlideObserver = () => {
  const container = document.querySelector('.media-reviews')

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        observer.disconnect()
        startAutoSlide()
      }
    })
  }, {
    threshold: 0
  })

  observer.observe(container!)
}

onMounted(() => {
  sliderRef.value.addEventListener('scroll', checkScroll)
  checkScroll()

  loadObservers()
  setAutoSlideObserver()
})

onUnmounted(() => {
  clearInterval(intervalId.value!)
})

onBeforeUnmount(() => {
  sliderRef.value.removeEventListener('scroll', checkScroll)
})
</script>

<script lang="ts">
export default {
  name: 'MediaReviews'
}
</script>

<template>
  <section class="media-reviews pt-40">
    <div class="w-full max-w-[97rem] mx-auto px-24 xl:px-0 md:grid grid-cols-[1fr_35rem] md:gap-x-24 lg:gap-x-70 overflow-hidden">
      <div class="mb-16 col-span-2">
        <span class="text-14 text-primary-600 tracking-[.05rem] px-40 lg:px-60">
          {{ t('sectionName') }}
        </span>

        <h3 class="max-w-[50rem] text-32 text-carbon-800 font-semibold px-40 lg:px-60">
          {{ t('title') }}
        </h3>
      </div>

      <div class="px-46 lg:px-60 relative">
        <div class="absolute left-0 top-6 lg:top-0">
          <button
            v-if="showLeftButton"
            id="media-reviews__navigation-btn-left"
            class="media-reviews__navigation-btn"
            data-lh-id="home-botton-slider__previous"
            aria-label="Anterior"
            @click="handlePreviousClick(true)">
            <span class="w-32 h-32 fill-primary-600 pointer-events-none">
              <NuxtIsland name="IconChevronLeft" />
            </span>
          </button>
        </div>

        <div class="absolute right-0 top-6 lg:top-0">
          <button
            v-if="showRightButton"
            id="media-reviews__navigation-btn-right"
            class="media-reviews__navigation-btn"
            data-lh-id="home-botton-slider__next"
            aria-label="Siguiente"
            @click="handleNextClick(true)">
            <span class="w-32 h-32 fill-primary-600 pointer-events-none">
              <NuxtIsland name="IconChevronRight" />
            </span>
          </button>
        </div>

        <div
          ref="sliderRef"
          class="slider"
          data-lh-id="home-slider-media-reviews">
          <div
            class="slider__slides"
            :style="`--n: ${reviews.length}`">
            <div
              v-for="(review, index) in reviews"
              :key="`review-${index}`"
              class="grid"
              :data-review="index">
              <div class="review-card">
                <p class="text-carbon-600 text-16 italic">
                  {{ rt(review.text) }}
                </p>

                <button
                  :id="`home-slider-review-link-cta-${index}`"
                  data-lh-id="home-slider-review-link-cta"
                  type="button"
                  class="mt-16 text-14 text-primary-600 font-medium underline"
                  :aria-label="t('buttonLink')"
                  @click="goToLink(rt(review.url))">
                  {{ t('buttonLink') }}
                </button>

                <img
                  class="mt-24"
                  :src="rt(review.logo_url)"
                  :alt="rt(review.company_name)"
                  :width="rt(review.width)"
                  :height="rt(review.height)"
                  loading="lazy">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full h-260 lg:h-230 lg:max-w-334 -mt-30 relative">
        <img
          src="https://lahaus.imgix.net/static/project_lunch_platform/logo-font-white.svg"
          class="w-280 h-290 md:h-350 md:w-full object-cover object-left-top absolute -right-[8rem] -top-10 md:-top-20 md:right-auto"
          width="280"
          height="290"
          loading="lazy"
          alt="La haus logo">
      </div>
    </div>
  </section>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
